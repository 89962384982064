// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$BW-BWR4-UI-primary: mat-palette($mat-indigo);
$BW-BWR4-UI-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$BW-BWR4-UI-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$BW-BWR4-UI-theme: mat-light-theme(
  (
    color: (
      primary: $BW-BWR4-UI-primary,
      accent: $BW-BWR4-UI-accent,
      warn: $BW-BWR4-UI-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($BW-BWR4-UI-theme);

/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.css";
@import "~tippy.js/dist/tippy.css";
@import "~tippy.js/themes/light-border.css";

$bwr-theme-background-color: #e1edfa;
$bwr-theme-color: #025aaa;
$bwr-white: #fff;
$bwr-black: #000;
$bwr-background-color: $bwr-white;
$bwr-font-color: $bwr-black;
$body-font-size: 14px;
$header-font-size: 18px;
$bristol-secondary-color: #3a9ee0;
.theme-btn {
  background-color: #0079c9;
}
.html-tag {
  overflow-y: hidden;
}
.bold {
  font-weight: bold;
}
.disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
  .click-area {
    //radio-button
    cursor: not-allowed;
    pointer-events: all !important;
  }
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: all !important;
}
.underline {
  text-decoration: underline;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-15 {
  font-size: 15px;
}
.mat-label-font {
  font-size: 13px;
  font-weight: 500;
}
.error-msg {
  color: red;
  font-size: 80%;
}
.red-color {
  color: #f44336
}
.green-color {
  color: #16e330;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.display-none {
  display: none;
}
/*-- spinner ---*/
.center,
.spinner {
  position: absolute !important;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.overlay {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.286);
  z-index: 10;
  top: 0;
  left: 0;
  position: fixed;
}
/*-----------*/
.fa-exclamation-triangle {
  font-size: 1 rem;
  color: #dc3545;
}

//tooltip styles
.icon {
  width: 1.5em;
  height: 1.2em;
  vertical-align: text-top;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.bwr-tooltip {
  background: #ffffff;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12),
    0px 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: #000 !important;
}
.icon-svg {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #cecbcb;
  border-radius: 15px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  border-radius: 15px;
  -webkit-box-shadow: inset 0 0 6px #cecbcb;
  background-color: #cecbcb;
}
.heading-4 {
  display: block;
  font-weight: 600;
  text-transform: none;
  color: $bristol-secondary-color;
  font-size: 1.5vw;
  line-height: 5.625vw;
  letter-spacing: 0vw;
  margin-bottom: 0.55em;
}
hr {
  margin-top: 2px;
  margin-bottom: 2px;
}
.bw-header3 {
  font-weight: 500;
  margin-bottom: 3px !important;
}
/* Applicant */
.bw-header4 {
  margin-bottom: 0px !important;
}
.bw-header5 {
  font-size: 15px !important;
  margin-bottom: 0px !important;
  font-weight: 500 !important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #003087;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #003087;
}
.mat-checkbox-layout {
  background: #ffffff;
  border-radius: 5px;
}
.mat-drawer-inner-container.ng-tns-c189-0 {
  overflow: initial;
}
/* Expansion Styles */
.bwr-mat-expansion-panel {
  &.mat-expansion-panel:not(.mat-expanded) {
    background: $bwr-theme-color;
    padding-left: 10px;
    margin-bottom: 10px;
    &:focus,
    &:hover {
      background: $bwr-theme-color;
      color: $bwr-white;
    }
    .mat-expansion-panel-header {
      background: $bwr-theme-color;
      padding-left: 0px;
      .mat-expansion-panel-header-title {
        color: $bwr-white !important;
      }
      &:focus,
      &:hover:not([aria-disabled="true"]) {
        background: $bwr-theme-color;
        color: $bwr-white;
      }
    }
  }
  &.mat-expansion-panel-spacing {
    margin-bottom: 10px !important;
  }
  .mat-expansion-panel-body {
    padding: 8px 10px 0px 10px;
  }
  &.mat-expanded {
    background: #fff;
  }
  .mat-expansion-panel-header.mat-expanded {
    height: 28px;
    background: $bwr-theme-color;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 10px;
    .mat-expansion-panel-header-title {
      color: $bwr-white;
    }
  }
  .mat-expansion-panel-header.mat-expanded:focus,
  .mat-expansion-panel-header.mat-expanded:hover {
    background: $bwr-theme-color;
  }
  .mat-expansion-panel-content {
    font-size: 400 14px ;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    letter-spacing: normal;
  }
  .mat-expansion-panel-header-title .open,
  .mat-expanded .mat-expansion-panel-header-title .close {
    display: inline-block;
    color: #fff;
    margin-left: 10px;
    font-size: 20px;
    opacity: 1;
  }
  .mat-expanded .mat-expansion-panel-header-title .close {
    font-size: 15px;
    opacity: 1;
    margin-top: 2px;
  }
  .mat-expanded .mat-expansion-panel-header-title .open,
  .mat-expansion-panel-header-title .close {
    display: none;
    //color: rgba(0, 0, 0, 0.87);
    color: #fff;
  }
  .material-icons {
    line-height: 1;
  }
  .info-icon {
    color: #158fef;
    line-height: 2.5;
    font-size: 19px;
    overflow: visible;
    cursor: pointer;
    &.checkbox-info {
      line-height: 1;
    }
  }
  .piggy-icon{
    cursor: pointer;
  }
  .mat-expansion-indicator {
    opacity: 0;
  }
}
.pay-plan-panel {
  .mat-expansion-panel-body {
    padding: 10px !important;
  }
}
.mat-form-field-flex .mat-form-field-outline {
  background-color: #fff;
  border-radius: 5px;
  color: #8f8e8e;
  // box-shadow: 1px 1px 1px 1px #0000001c;
  box-sizing: border-box;
}
// /* Dense Fields */
.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.5em 0px 0.75em 0px;
  .mat-form-field-label {
    font-size: 13px;
  }
}
/* textfield default behaviour change*/
.mandatory-field.mat-form-field-appearance-outline .mat-form-field-outline {
  color: #158fef;
}
.mandatory-field .mat-form-field-outline {
  background: #f3f8fd;
}
.mat-form-field-wrapper {
  padding-bottom: 12px !important;
}
.mat-error {
  font-size: 10px;
  margin-top: 2px;
}
/* Mask */
.mask {
  // position: absolute;
  left: 37.5%;
  right: 50%;
  top: 57.5%;
  bottom: 17.5%;

  background: #585858;
  transform: rotate(-90deg);
}

/* Icons/*Functional (Material Design)/Arrow - Up */
.expand-collapse {
  //position: absolute;
  width: 40px;
  height: 40px;
  left: calc(50% - 40px / 2 - 214px);
  top: calc(50% - 40px / 2 - 708.5px);
  display: inline-flex;
  vertical-align: middle;
}

/* expand collapse */
.mat-form-field + .mat-form-field {
  margin-left: 8px;
}

/* Clickarea */
.click-area {
  // position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  background: #ffffff;
  border-radius: 5px;
}
/* Angulat Material */

/* Angulat Material */
.bwr-alert-primary {
  padding: 0rem 1.25rem;
  border: 1px solid $bwr-theme-color;
}
.field-full-width-80 {
  width: 80%;
}
.field-full-width-60 {
  width: 60%;
}
.field-full-width-52 {
  width: 52%;
}
.field-full-width-50 {
  width: 50%;
}
.field-full-width {
  width: 100%;
}

/* page sizing */

.line-height-3 {
  line-height: 3;
}
.btn-next {
  background: #158fef;
  color: #fff;
  min-width: 85px;
}
.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  background: #d2d2d2;
  max-width: 100%;
}

/* checkbox  section */
.checkbox-section {
  display: flex;
  align-content: center;
  align-items: center;
  height: 28px;
}

.checkbox-margin {
  label {
    // margin-bottom: 0px !important;
  }
}

/* checkbox color change*/
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #003087; //#3f51b5;
}

/* radio selected label name to be bold */
.mat-radio-checked .mat-radio-label-content {
  font-weight: bold;
  white-space: break-spaces;
}
/* checkbox selected label name to be bold */
.mat-checkbox-checked .mat-checkbox-label {
  font-weight: bold;
}

.rates-prem {
  font-weight: bold;
  font-weight: 600 !important;
  font-size: 40px !important;
  line-height: 50px;
  color: #013692;
}

.add-btn {
  border: 1px solid #158fef;
  color: #158fef;
  //margin-top: 90%;
  margin-left: 10%;
  width: auto;
  max-width: fit-content;
  background: #fff;
  padding: 7px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  box-shadow: 1px 2px 4px 2px #cbcbcb;
}

//    UMS Field Style update for VA state.

.umsHelpTest {
  position: absolute;
  padding: 0 5px;
}

.umsHelpTestReview {
  position: absolute;
  padding: 0;
}
